import { MouseEventHandler, useState, useEffect } from "react";

// hooks
//@ts-ignore
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import windowSize from "src/hooks/useWindowSize";
import { getSigner } from "src/utils";

// utils
import { shorter, TokenType } from "src/utils";
import StandardTokenTestABI from "src/utils/abis/DropLockerToken.json";

// components
import Modal from "src/components/Modal";
import Button from "src/components/Button";
import Loader from "src/components/Loader";

// third party
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//@ts-ignore
import * as Yup from "yup";
import toast from "react-hot-toast";

let standardABI = StandardTokenTestABI.abi;
let standardBytecode = StandardTokenTestABI.bytecode;
let dropLockerPoolAddr = "";

interface ParentProps {
  isShow: Boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
}
interface TokenInfoProps {
  tokenName: string;
  tokenSymbol: string;
  tokenSupply: number;
  tokenDecimal: number;
  tradingLimit: number;
  timeLimit: number;
  firstMonth: number;
  secondMonth: number;
  thirdMonth: number;
  fourthMonth: number;
  fifthMonth: number;
  sixthMonth: number;
}

const BepToken = ({ isShow, onClose }: ParentProps) => {
  const validationSchema = Yup.object().shape({
    tokenName: Yup.string().required("Token name is required"),
    tokenSymbol: Yup.string().required("Token symbol is required"),
    tokenSupply: Yup.number().required("Token supply is required"),
    tokenDecimal: Yup.number().required("Token Decimal is required"),
    tradingLimit: Yup.number().min(0).required("Trading Limit is required"),
    timeLimit: Yup.number().min(0).required("Time Limit is required"),
    firstMonth: Yup.number().min(0).required("First Month is required"),
    secondMonth: Yup.number().min(0).required("Token Decimal is required"),
    thirdMonth: Yup.number().min(0).required("Token Decimal is required"),
    fourthMonth: Yup.number().min(0).required("Token Decimal is required"),
    fifthMonth: Yup.number().min(0).required("Token Decimal is required"),
    sixthMonth: Yup.number().min(0).required("Token Decimal is required"),
  });
  const initialState: TokenInfoProps = {
    tokenName: "",
    tokenSymbol: "",
    tokenSupply: 0,
    tokenDecimal: 18,
    tradingLimit: 0,
    timeLimit: 0,
    firstMonth: 0,
    secondMonth: 0,
    thirdMonth: 0,
    fourthMonth: 0,
    fifthMonth: 0,
    sixthMonth: 0,
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TokenInfoProps>({
    resolver: yupResolver(validationSchema),
  });
  const [tokenInfo, setTokenInfo] = useState<TokenInfoProps>({
    ...initialState,
  });
  const [deployAddr, setDeployAddr] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { account, chainId } = useWeb3React();
  const { width } = windowSize();
  const Network = {
    1: { name: "Ethereum Mainnet", url: "https://etherscan.io/token/" },
    3: { name: "Ropsten Testnet", url: "https://etherscan.io/token/" },
    4: { name: "Rinkeby Testnet", url: "https://etherscan.io/token/" },
    5: { name: "Goerli Testnet", url: "https://goerli.etherscan.io/token/" },
    56: { name: "BSC Mainnet", url: "https://bscscan.com/token/" },
    97: { name: "BSC Testnet", url: "https://testnet.bscscan.com/token/" },
    11155111: {
      name: "Seplia Testnet",
      url: "https://sepolia.etherscan.io/token/",
    },
  };
  const Network_Address = {
    1: { name: "Ethereum Mainnet", url: "https://etherscan.io/address/" },
    3: { name: "Ropsten Testnet", url: "https://etherscan.io/address/" },
    4: { name: "Rinkeby Testnet", url: "https://etherscan.io/address/" },
    5: { name: "Goerli Testnet", url: "https://goerli.etherscan.io/address/" },
    56: { name: "BSC Mainnet", url: "https://bscscan.com/address/" },
    97: { name: "BSC Testnet", url: "https://testnet.bscscan.com/address/" },
    11155111: {
      name: "Seplia Testnet",
      url: "https://sepolia.etherscan.io/address/",
    },
  };
  const AbiInfo = {
    56: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    97: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    1: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    5: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
    11155111: {
      abi: StandardTokenTestABI.abi,
      bytecode: StandardTokenTestABI.bytecode,
    },
  };
  const DropLockerPool = {
    56: "0x62a6c69d2459b36647f9315e886631e1f044d57e",
    97: "0x492fdD57dCdDdA1A663B9d9b78C37D89D4e13D4e",
  };

  useEffect(() => {
    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, chainId]);

  useEffect(() => {
    if (chainId) {
      standardABI = AbiInfo[chainId]["abi"];
      standardBytecode = AbiInfo[chainId]["bytecode"];
      dropLockerPoolAddr = DropLockerPool[chainId];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account]);

  function onChange(e: { target: { name: any; value: any } }) {
    const { name, value } = e.target;
    setTokenInfo({
      ...tokenInfo,
      [name]: value,
    });
  }
  async function onSubmit(data: TokenInfoProps) {
    if (!account) {
      toast.error("Connect your metamask to mint");
      return;
    }
    if (dropLockerPoolAddr == "") {
      toast.error("Drop locker pool contract is not set");
      return;
    }
    try {
      setIsLoading(true);
      const signer = await getSigner();
      const factory = new ethers.ContractFactory(
        standardABI,
        standardBytecode,
        signer
      );
      const contract = await factory.deploy(
        data.tokenName,
        data.tokenSymbol,
        ethers.utils.parseEther(data.tokenSupply.toString()),
        dropLockerPoolAddr,
        data.timeLimit,
        data.tradingLimit,
        {
          firstMonth: data.firstMonth,
          secondMonth: data.secondMonth,
          thirdMonth: data.thirdMonth,
          fourthMonth: data.fourthMonth,
          fifthMonth: data.fifthMonth,
          sixthMonth: data.sixthMonth,
        }
      );
      setDeployAddr(contract.address);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }
  function onReset() {
    setTokenInfo(initialState);
    reset();
  }

  function goToUnlockPool() {
    if (!dropLockerPoolAddr) return;
    window.open(Network_Address[chainId]["url"] + dropLockerPoolAddr);
  }

  return (
    <Modal isOpen={isShow} className="creator-modal" onClose={onClose}>
      <div className={`overlay ${isLoading ? "show-overlay" : "hide-overlay"}`}>
        <Loader />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="heading">
          Create Your {TokenType[chainId]} DropLocker Token
        </h1>
        {deployAddr && !isLoading && chainId ? (
          <div className="content">
            <h2 className="heading">Successfully deployed!</h2>
            <a
              href={Network[chainId]["url"] + deployAddr}
              className="heading"
              target="_blank"
              rel="noreferrer"
            >
              <span>Click here to check your token</span>
            </a>
          </div>
        ) : (
          <>
            <div className="content">
              <div className="row">
                <label htmlFor="">Selected Account *</label>
                <span>
                  {account
                    ? width < 640
                      ? shorter(account)
                      : account
                    : "Please connect your metamask"}
                </span>
              </div>
              <div className="row">
                <label htmlFor="">Current Network *</label>
                <span>
                  {chainId
                    ? Network[chainId]["name"]
                    : "Please connect your metamask"}
                </span>
              </div>
              <div className="row">
                <label htmlFor="">Drop Unlock Address *</label>
                <span onClick={goToUnlockPool} className="cursor-pointer">
                  {dropLockerPoolAddr || "Please connect your metamask"}
                </span>
              </div>
              <div className="row">
                <label htmlFor="">Name *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={tokenInfo.tokenName}
                    {...register("tokenName")}
                    name="tokenName"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenName?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Symbol *</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    value={tokenInfo.tokenSymbol}
                    {...register("tokenSymbol")}
                    name="tokenSymbol"
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenSymbol?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Initial Supply *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.tokenSupply}
                    {...register("tokenSupply")}
                    name="tokenSupply"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenSupply?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Decimals *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.tokenDecimal}
                    {...register("tokenDecimal")}
                    name="tokenDecimal"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.tokenDecimal?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Trading Time Limit (m) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.tradingLimit}
                    {...register("tradingLimit")}
                    name="tradingLimit"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.timeLimit?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Unlock Time Limit (m) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.timeLimit}
                    {...register("timeLimit")}
                    name="timeLimit"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.timeLimit?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">First Month (%) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.firstMonth}
                    {...register("firstMonth")}
                    name="firstMonth"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.firstMonth?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Second Month (%) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.secondMonth}
                    {...register("secondMonth")}
                    name="secondMonth"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.secondMonth?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Third Month (%) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.thirdMonth}
                    {...register("thirdMonth")}
                    name="thirdMonth"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.thirdMonth?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Fourth Month (%) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.fourthMonth}
                    {...register("fourthMonth")}
                    name="fourthMonth"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.fourthMonth?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Fifth Month (%) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.fifthMonth}
                    {...register("fifthMonth")}
                    name="fifthMonth"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.fifthMonth?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <label htmlFor="">Six Month (%) *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="input"
                    value={tokenInfo.sixthMonth}
                    {...register("sixthMonth")}
                    name="sixthMonth"
                    onChange={onChange}
                    min={0}
                  />
                  <div className="invalid-feedback">
                    {errors.timeLimit?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <Button variant="primary" submit>
                Mint
              </Button>
              <Button variant="fill" onClick={onReset}>
                Reset
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export default BepToken;
